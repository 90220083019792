<template>
  <div class="columns is-multiline has-background-bluedark2">
    
    <div class="column is-half">
      <div class="ad-not-box-chart has-background-bluedark">
        <h4 class="title">Engajamento Diário</h4>
        <p class="subtitle">Interações com todas as notificações por dia.</p>
      </div>
      <v-histogram v-if="!loadingNotificationMetrics" :chartData="chartEngajament" :chartSettings="chartSettings" chartWidth="100%"></v-histogram>
    </div>

    <div v-if="this.Metricas.length > 0" class="column is-half">
      <Boxes :metrics="this.Metricas"></Boxes>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import NotificationMixin from '@/modules/requests/Notification'
import Histogram from '@/components/helpers/HistogramChart'
import Boxes from './components/Boxes'

export default {
  name: 'Analytics',
  mixins: [NotificationMixin],
  components: {
    'v-histogram': Histogram,
    Boxes
  },
  data() {
    return {
      chartSettings: {
        metrics: ['total'],
        dimension: ['data_criacao']
      }
    }
  },
  async created() {

    const params = {
      _date_st: moment(moment().subtract(10, 'days')).format('YYYY-MM-DD'),
      _date_en: moment().format('YYYY-MM-DD')
    }

    await this.getMetrics(params)
  },
  computed: {
    chartEngajament () {
      let columns = [ 'data_criacao', 'total']
      let aux = JSON.parse(JSON.stringify(this.Metricas))
      
      let obj = {}
      for (let el of aux) {
        let _dc = moment(el.data_criacao).format('DD/MM')
        if (!obj[_dc]) obj[_dc] = 0
        obj[_dc] ++
      }
      
      let rows = Object.keys(obj).map(e => {
        return {
          data_criacao: e,
          total: obj[e]
        }
      })

      return { rows, columns }
    }
  }
}
</script>

<style scoped>
.ad-not-box-info {
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 240px;
}
.ad-not-box-chart {
  border-radius: 4px;
  width: 100%;
  padding: 1em;
}
.ad-not-box-info label {
  font-size: 1em;
  color: white;
  margin-bottom: 1em;
}
.ad-not-box-info p {
  font-size: 3em;
  font-weight: bold;
}
</style>