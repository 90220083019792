<template>
  <div
    class="modal-card"
    style="width: auto"
    :class="{ 'no-margin': $mq == 'sm' }"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">Notificação</p>
    </header>

    <form-content></form-content>
  </div>
</template>

<script>
import Form from "./Form";

export default {
  name: "Modal",
  components: {
    "form-content": Form,
  },
};
</script>

<style scoped>
</style>