<template>
  <div class="card has-background-bluedark2" :style="{ height: '100%' }">

    <header class="card-header has-background-bluedark" :style="{ borderRadius: '4px 4px 0 0', padding: '0.75em' }">
      <div>
        <h4 class="title">Comportamento</h4>
        <p class="subtitle">Áreas que os usuários utilizam, o comportamento diante às notificações</p>
      </div>
    </header>

    <div class="card-content">
      <div class="columns is-multiline">
    
        <div v-for="(op, i) in infos.dados" :key="op" class="column is-half">
          <div class="ad-not-box-info has-background-bluedark">
            <label>{{ i | capitalize}}</label>
            <p class="ad-box-subtitle">{{ op[0].descricao }}</p>
            <p>{{ op.length }}</p>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Boxes',
  props: {
    metrics: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  computed: {
    infos () {
      let aux = JSON.parse(JSON.stringify(this.metrics))
      , obj = {}
      , completos = 0, incompletos = 0
      
      for (let el of aux) {
        if (!obj[el.area]) obj[el.area] = []
        obj[el.area].push(el)

        if (el.status === 'completo') completos ++
        if (el.status === 'incompleto') incompletos ++
      }
      return  {
        completos,
        incompletos,
        dados: obj
      }
    }
  }
}
</script>

<style scoped>
.ad-not-box-info {
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.ad-not-box-info label {
  font-size: 1.2em;
  color: white;
  margin-bottom: 0.5em;
}
.ad-not-box-info p {
  font-size: 3em;
  font-weight: bold;
}
.ad-box-subtitle {
  font-size: 0.9em !important;
  font-weight: normal !important;
}
</style>