<template>
  <ve-histogram :data="chartData" :colors="chartColors" :settings="chartSettings" :mark-line="chartMark" :height="chartHeight" :width="chartWidth"  :theme-name="'dark'"></ve-histogram>
</template>

<script>
import VeHistogram from 'v-charts/lib/histogram.common'

export default {
  props: ['chartData','chartColors','chartSettings','chartMark','chartHeight','chartWidth'],
  components: {
    've-histogram': VeHistogram
  }
}
</script>

<style>
</style>
