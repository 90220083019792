<template>
  <section>
    <div class="columns is-multiline has-background-bluedark2">
      <div class="column is-full">

        <div class="level">
          <div class="level-left">
            <div>
              <h1 class="title" id="title">Notificação</h1>
              <h2 class="subtitle">Visualizar fluxo completo de notificação</h2>
            </div>
          </div>
          <div class="level-right">
            <b-button type="is-success" @click="openModal">Criar</b-button>
          </div>
        </div>

      </div>
    </div>
      
    <div class="columns is-multiline has-background-bluedark2">
      
      <!-- <div class="column is-full">
        
      </div> -->

      <div class="column is-full">
        <List></List>
      </div>
      
    </div>

    <Analytics></Analytics>

    <b-modal @close="closeModal()" :active.sync="notificationActiveEditModal" :width="700"  :full-screen="$mq == 'sm'">
      <ModalData></ModalData>
    </b-modal>
  </section>
</template>

<script>
import NotficationMixin from '@/modules/requests/Notification'
import List from './List'
import ModalData from './Modal'
import Analytics from './Analytics'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NotificationPanel',
  mixins: [ NotficationMixin ],
  components: {
    List,
    ModalData,
    Analytics
  },
  data() {
    return {
      selectEdit: null
    }
  },
  async created () {
    let loader = this.$loading.show() 

    Promise.all([
      this.getTypes(),
      this.getTargetsGroups(),
    ])
    .then(res => {
      loader.hide()
    })
  },
  computed: {
    ...mapGetters(['notificationActiveEditModal'])
  },
  methods: {
    ...mapActions(['setNotificationEdit', 'setNotificationOpenEdit']),
    openModal () {
      this.setNotificationEdit({})
      this.setNotificationOpenEdit(true)
    },
    closeModal () {
      this.setNotificationEdit({})
      this.setNotificationOpenEdit(false)
    } 
  }
}
</script>

<style scoped>

</style>