<template>
  <div>
    <div class="modal-card-body" :style="{ height: '60vh', overflow: 'auto' }">
      <div class="columns is-multiline">
        <div class="column is-one-third">
          <b-field label="Tipo *">
            <b-select
              v-model="form.id_type"
              required
              expanded
              placeholder="Selecione um tipo"
            >
              <option
                v-for="option in notificationTypes"
                :value="option.id"
                :key="'tp-' + option.id"
              >
                {{ option.description | capitalize }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Grupo de Destino *">
            <b-select
              v-model="form.id_target_group"
              required
              expanded
              placeholder="Selecione um destino"
            >
              <option
                v-for="option in notificationTargetsGroups"
                :value="option.id"
                :key="'gd-' + option.id"
              >
                {{ option.slug | capitalize }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <div :style="{ marginTop: $mq != 'sm' ? '1em' : '0' }">
            <b-switch
              v-model="form.is_active"
              :value="+true"
              type="is-success"
              :style="{ marginBottom: '1em' }"
            >
              Ativo
            </b-switch>
            <b-switch
              :disabled="form.id_type !== 1"
              v-model="form.is_custom"
              :value="+true"
              type="is-success"
            >
              Personalizado
            </b-switch>
          </div>
        </div>
        <div
          v-if="
            form.id_target_group &&
            notificationTargetsGroups.filter(
              (el) => el.id == form.id_target_group
            )[0].slug !== 'todos'
          "
          class="column is-full"
        >
          <div class="section has-background-bluedark2" style="border-radius: 4px">
            <b-field
              grouped
              message="Defina os ID de acordo com seu destino, como: ID PLANO ou ID USUARIO"
            >
              <b-input
                @keyup.native.enter="addRecipient"
                v-model="recipient_input"
              ></b-input>
              <p class="control">
                <b-button
                  @click="addRecipient"
                  type="is-info"
                  icon-left="plus"
                  icon-pack="fas"
                ></b-button>
              </p>
            </b-field>

            <span v-if="form.id_target_group">
              <b
                >Lista de IDS -
                {{
                  notificationTargetsGroups.filter(
                    (el) => el.id == form.id_target_group
                  )[0].slug
                }}</b
              >
            </span>
            <div v-if="recipient.length > 0" :style="{ marginTop: '0.5em' }">
              <div
                v-for="(rep, i) of recipient"
                :key="rep"
                class="level has-background-defaultbg"
                :style="{
                  padding: '0 0 0 0.75em',
                  borderRadius: '4px',
                  marginBottom: '0.5em',
                }"
              >
                <div class="level-left">
                  {{ rep }}
                </div>
                <div class="level-right">
                  <b-button
                    @click="excludeItemRecipient(i)"
                    type="is-danger"
                    icon-pack="fas"
                    icon-right="trash"
                  ></b-button>
                </div>
              </div>
            </div>
            <div v-else>
              <section :style="{ padding: '1em' }">
                <div class="has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-medium"> </b-icon>
                  </p>
                  <p>Sem Registros.</p>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <b-field
            label="Slug *"
            message="Caso seja personalizado, o layout será relacionado a este campo (slug)"
          >
            <b-input v-model="form.slug" required></b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field
            label="Origem *"
            message="Referência ao tipo visual da notificação, especificando a origem da mesma"
          >
            <b-input v-model="form.origem" required></b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Nivel *">
            <b-select
              v-model="form.level"
              required
              expanded
              placeholder="Selecione um Nivel"
            >
              <option
                v-for="option in ['urgente', 'normal']"
                :value="option"
                :key="option"
              >
                {{ option | capitalize }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-full">
          <b-field label="Mensagem *">
            <b-input v-model="form.message" required type="textarea"></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Link">
            <b-input v-model="form.link"></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Link - Texto do Botão">
            <b-input v-model="form.link_label"></b-input>
          </b-field>
        </div>
      </div>
      <span>* Campos Obrigatórios</span>
    </div>
    <footer class="modal-card-foot">
      <b-button type="is-danger" @click="close()" outlined>Cancelar</b-button>
      <b-button type="is-success" @click="register()">Salvar</b-button>
    </footer>
  </div>
</template>

<script>
import NotificationMixin from "@/modules/requests/Notification";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Form",
  mixins: [NotificationMixin],
  data() {
    return {
      recipient_input: "",
      recipient: [],
      editable: false,
      form: {
        id_type: null,
        id_target_group: null,
        level: "",
        slug: "",
        message: "",
        origem: "default",
        link: "",
        link_label: "",
        is_active: 0,
        is_custom: 0,
        expires_at: null,
      },
      required_field: {
        id_type: "number",
        id_target_group: "number",
        level: "string",
        slug: "string",
        message: "string",
      },
    };
  },
  created() {
    if (this.notificationActiveEditModal) {
      this.editable = Object.keys(this.notificationEdit).length > 0;
      if (this.editable) {
        this.form = JSON.parse(JSON.stringify(this.notificationEdit));
        this.recipient = this.form.targets_group;
      }
    }
  },
  computed: {
    ...mapGetters(["notificationEdit", "notificationActiveEditModal"]),
  },
  methods: {
    ...mapActions(["setNotificationOpenEdit"]),
    close() {
      this.setNotificationOpenEdit(false);
    },
    addRecipient() {
      this.recipient.push(this.recipient_input);
      this.recipient_input = "";
    },
    excludeItemRecipient(index) {
      if (this.recipient[index]) {
        let aux = this.recipient.filter((e, i) => i != index);
        this.recipient = [...aux];
      }
    },
    async register() {
      for (let el in this.required_field) {
        if (typeof this.form[el] != this.required_field[el]) {
          this.$toasted.error("Erro no campo: " + el, {
            duration: 3000,
            position: "top-center",
          });
          return;
        }
      }

      this.form.is_active = this.form.is_active ? 1 : 0;
      this.form.is_custom = this.form.is_custom ? 1 : 0;
      if (!this.editable) {
        let { id } = await this.setNotification(this.form);

        const params = {
          id_notification: id,
          recipient: this.recipient,
        };

        await this.setTargetGroup(params);
      } else if (this.editable) {
        this.form.targets_group = [...this.recipient];
        await this.updateNotification(this.form.id_notification, this.form);
      }

      this.getAdminNotifications();

      this.close();
    },
  },
};
</script>
