<template>
  <div class="card has-background-bluedark2">
    
    <header class="card-header has-background-bluedark" :style="{ borderRadius: '4px 4px 0 0' }">
      <p class="card-header-title">Lista</p>
    </header>

    <div class="card-content">
      <b-table
        blueDark2
        v-if="!loadingNotification"
        :data="notificacoesAdmin"
        :loading="loadingNotification"
        @page-change="page => changePage(page)"
        backend-pagination
        paginated
        per-page="10"
        :current-page="notificacoesAdminPage"
        :total="notificacoesAdminTotal"
      >
        <template slot-scope="props">

          <b-table-column field="ativo" label="Status" width="20">
            <b-tag
              rounded
              :type="props.row.ativo ? 'is-success' : 'is-danger'"
              style="padding: 0; width: 1.5em !important; height: 1.5em !important; border-radius: 50%;"
            ></b-tag>
          </b-table-column>

          <b-table-column field="id" label="ID" width="20">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="tipo" label="Tipo" width="100">
            {{ props.row.tipo }}
          </b-table-column>

          <b-table-column field="destino" label="Destino" width="100">
            {{ props.row.destino }}
          </b-table-column>

          <b-table-column field="slug" label="Slug" width="100">
            {{ props.row.slug }}
          </b-table-column>

          <b-table-column field="nivel" label="Nivel" width="100">
            <b-tag :type="formatterLevel(props.row.nivel)">
              {{ props.row.nivel }}
            </b-tag>
          </b-table-column>

          <b-table-column field="mensagem" label="Mensagem">
            {{ props.row.mensagem }}
          </b-table-column>

          <b-table-column field="data_criacao" label="Criado em" centered width="150">
            <p>{{ _moment(props.row.data_criacao).format('DD/MM/YYYY') }}</p>
            <p>{{ _moment(props.row.data_criacao).format('HH:mm') }}</p>
          </b-table-column>

          <b-table-column field="acoes" label="Ações" centered width="120">
            <!-- <b-button disabled type="is-primary" icon-pack="fas" size="is-small" icon-right="share" outlined @click="editUser(props.row)"/> -->
            <b-button type="is-info" icon-pack="fas" size="is-small" icon-right="edit" outlined @click="editItem(props.row)"/>
            <b-button type="is-danger" icon-pack="fas" size="is-small" icon-right="trash" outlined @click="softDelete(props.row)"/>
          </b-table-column>
          
        </template>

      
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>Sem Registros.</p>
            </div>
          </section>
        </template>

        <template slot="bottom-left">
          <div class="ga-legendas">

            <div class="ga-display">
              <div class="ga-leg-align-items">
                <div class="ga-leg-rd has-background-success"></div>
                <span>Ativada</span>
              </div>
              <div class="ga-leg-align-items">
                <div class="ga-leg-rd has-background-danger"></div>
                <span>Desativada</span>
              </div>
            </div>
          </div>
        </template>

        

      </b-table>
    </div>
    
  </div>
</template>

<script>
import moment from 'moment'
import NotificationMixin from '@/modules/requests/Notification'
import { mapActions } from 'vuex'

export default {
  name: 'Lista',
  mixins: [ NotificationMixin ],
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      page: 1
    }
  },
  async created () {
    await this.getAdminNotifications()
  },
  methods: {
    ...mapActions(['setNotificationEdit', 'setNotificationOpenEdit']),
    _moment(e) {
      return moment(e)
    },
    formatterLevel(level) {
      if (level == 'urgente') return 'is-danger'
      if (level == 'normal') return 'is-warning'
    },
    async changePage(page) {
      await this.setNotificationsAdminPage(page)
      this.getAdminNotifications()
    },
    editItem (el) {
      this.setNotificationEdit({
        id_notification: el.id,
        id_type: el.id_tipo,
        id_target_group: el.id_destino,
        targets_group: el.grupo_destino,
        level: el.nivel,
        slug: el.slug,
        message: el.mensagem,
        origem: el.origem,
        link: el.link,
        link_label: el.link_label,
        is_active: el.ativo,
        is_custom: el.personalizado,
        expires_at: el.data_expiracao
      })
      this.setNotificationOpenEdit(true)
    },
    async softDelete (el) {
      this.$buefy.dialog.confirm({
        title: 'Excluir Notificação',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        message: 'Tem certeza que deseja excluir esta Notificação?',
        canCancel: true,
        confirmText: 'Sim',
        cancelText: 'Não', 
        onConfirm: async () => {
          await this.softDeleteNotification(el.id)
          this.getAdminNotifications()
        }
      })
    }
  }
}
</script>

<style scoped>
.ga-legendas {
  width: 250px;
  margin-top: 1em;
}
.ga-legendas label {
  margin-bottom: 1em;
}
.ga-display {
  display: flex;
  flex-direction: row;
}
.ga-leg-align-items {
  margin: 0 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ga-leg-align-items:first-child {
  margin-left: 0;
}
.ga-leg-align-items:last-child {
  margin-bottom: 0;
}
.ga-leg-align-items span {
  font-size: 1em;
  color: rgb(255, 255, 255);
}
.ga-leg-rd {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 0.5em;
}
</style>